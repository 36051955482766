var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('main',{attrs:{"id":"playlistContent"}},[(_vm.isLoading)?_c('div',{staticClass:"loadingContainer"},[_c('Loading')],1):_c('div',{staticClass:"content"},_vm._l((_vm.tracks),function(track,index){return _c('Track',{key:track.track.id,attrs:{"index":index + 1,"onPlay":function () { return _vm.playTrack(index); },"isPlaying":track.track.id === _vm.nowInfo.id,"trackName":track.track.name,"albumName":track.track.album.name,"artistName":track.track.artists[0].name,"trackDuration":track.track.duration_ms}})}),1)]),_c('Aside',{attrs:{"image":_vm.playlist.images && _vm.playlist.images[0].url,"onClickPlay":function () { return _vm.playTrack(0); },"title":_vm.playlist.name,"subTitle":_vm.playlist.owner && _vm.playlist.owner.display_name,"footerText":((_vm.playlist.tracks && _vm.playlist.tracks.total) + " músicas"),"options":[
      {
        name: 'random',
        action: _vm.playPlaylistRandom,
      },
      {
        name: 'like',
        action: _vm.savePlaylist,
        isActive: _vm.isFollowing,
      } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }